
.legal-marquee {
  margin: calc(var(--global-padding) * 2) 0;
  text-transform: uppercase;
}

.legal {
  padding: var(--global-padding);

  h2 {
    margin-top: calc(var(--global-padding) * 2);
  }

  h3 {
    margin-top: var(--global-padding);
  }

  p {
    margin-bottom: 1rem;
    max-width: 1000px;
  }
 }
