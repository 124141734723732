.footer {
  text-align: center;
  color: var(--local-foreground);

  a, a:visited, a:hover, a:active {
    color: inherit;
  }

  .footer-wrapper {
    padding-left: var(--global-padding);
    padding-right: var(--global-padding);
    padding-top: var(--global-padding);
    // padding-bottom: var(--global-padding);
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: var(--global-padding);

    @include respond-to('medium') {
      flex-direction: row-reverse;
      text-align: left;
    }
  }

  // color: $white; //onblack

  // &--onoffblack, &--onorange {
  //   color: $white;
  // }

  .back-to-top {
    padding: 0.8rem;
    background-color: var(--local-foreground);
    border-radius: 50%;
    cursor: pointer;

    svg {
      color: var(--local-background);
      display: block;
      width: 1rem;
      height: 1rem;
    }
  }

  // &--onwhite, &--onoffwhite {
  //   color: $black;

  //   .back-to-top {
  //     color: $white;
  //     background-color: $black;
  //   }
  // }

  &-contact {

    &-item {
      display: block;
    }

    .label {
      font-family: "GTF Kubik";
      margin-right: 4px;
    }

  }

  &-legal {
    white-space: nowrap;
    a {
      display: inline;
    }
  }


  &-socials {
    display: flex;

    a {
      display: flex;
      align-items: center;
      margin: 0 0.5rem;
    }

    svg {
      display: block;
    }
  }

}

.footer-spacer-bottom {
  height: var(--global-padding);
  position: relative;
  display: block;
}
