.intro {
  position: relative;
  padding: var(--global-padding);
  padding-bottom: calc(var(--global-padding) * 2);
  z-index: 20;

  &--reel {
    height: 100lvh;
  }

  &--contact {
    .intro-headline, .intro-text {
      @include respond-to('medium') {
        width: 80%;
      }
    }
  }

  &--can {
    display: flex;
    flex-direction: column;

    .intro-headline {
      font-size: 10vw;
    }

    @include respond-to('medium') {
      flex-direction: row;

      .intro-headline, .intro-text {
        width: calc(100% - var(--global-padding));
      }
    }

    .text-wrapper {
      margin-bottom: var(--global-padding);
    }
  }

  &-headline {
    margin-bottom: var(--global-padding);

    mark {
      color: inherit;
      background-color: transparent;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;

      span {
        display: inline-block;
        transition: transform 200ms;
        // transform: translate3d(0,0,0);
        transform: translate3d(0, 110%, 0);
      }
    }
  }

  &-text {
    font-size: var(--copy-size);

    strong {
      font-family: 'GTF Kubik';
    }
  }

  &-headline, &-text {
    width: 100%;

    // @include respond-to('medium') {
    //   width: 66.666%;
    // }
  }

  &-can-wrapper {
    // border: 1px solid green;
    display: flex;
    justify-content: flex-end;
    align-self: start;
    position: absolute;
    opacity: 0;
    pointer-events: none;

    @include respond-to('large') {
      justify-content: center;
      min-width: 40vw;
    }
  }

  &__reel {
    position: fixed;
    display: block;
    width: 100%;
    height: 100lvh;
    inset: 0;
    overflow: hidden;
    // background-color: $black;

    video {
      height: 100%;
      width: 177.77777778lvh; /* 100 * 16 / 9 */
      min-width: 100%;
      min-height: 56.25vw; /* 100 * 9 / 16 */
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      // opacity: 0.6;
      pointer-events: none;
    }
  }

  &__age-verification {
    position: absolute;
    z-index: 20;
    bottom: 25%;
    left: 50%;
    height: auto;
    transform: translate3d(-50%, 0, 0);
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__claim {
    width: 80%;
    min-width: 260px;
    height: auto;
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__yes-button {
    position: relative;
    font-size: 1.2rem;
    // background-color: transparent;
    background-color: black;
    color: white;
    border-radius: 40px;
    padding: 0.5rem 1rem;
    // border: 2px solid black;
    white-space: nowrap;

    &:hover {
      overflow: visible;
      background-color: $orange;

      &:after {
        // content: "good for you";
        content: "Come in!";
        display: inline-block;
        position: absolute;
        font-family: 'Labil Grotesk', 'Helvetica', sans-serif;
        left: 50%;
        bottom: -1.5rem;
        width: 200%;
        text-align: center;
        width: 200%;
        font-size: 12px;
        transform: translateX(-50%);
      }
    }
  }

  &__question {
    font-size: var(--button-text-size);
    line-height: 1.5;
    margin-bottom: 1rem;
    line-height: 1;
    // white-space: nowrap;
    text-align: center;
  }

  // .button {
  //   margin: 0 0.5rem;
  // }
}


// .onload .intro-headline {

//   mark {
//     span {
//       transform: translate3d(0, 110%, 0);
//     }
//   }
// }
