.contact-headline {
  width: 100%;
  margin-top: calc(var(--global-padding) * 4);
  text-align: center;
  padding: 0 var(--global-padding);
}

.contact-error {
  width: 100%;
  text-align: center;
  color: red;
  padding: var(--global-padding);
}

.alert.error {
  color: red;
  width: 100%;
  text-align: center;
  padding: 1rem;
  margin: calc(var(--global-padding) * 0.5);
}

.contact-success {
  display: none;
  margin-bottom: calc(var(--global-padding) * 4);
}

.contactform {
  //border: 1px solid red;
  padding: var(--global-padding);
  max-width: 1280px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-top: calc(var(--global-padding) * 16);

  @include respond-to('medium') {
    margin-top: calc(var(--global-padding) * 4);
  }

  h2, h3 {
    width: 100%;
    text-align: center;
    margin-bottom: var(--global-padding);
  }

  .field {
    background-color: $offwhite;
    border-bottom: none;
    position: relative;
    font-size: var(--copy-size);
    font-family: 'Labil Grotesk', 'Helvetica', sans-serif;

    @include respond-to('medium') {
      border-width: 0.3vw;
    }

    &--message {
      padding-top: var(--global-padding);
      border-top: 3px solid $orange;

      @include respond-to('medium') {
        border-width: 0.3vw;
      }

      label {
        position: relative;
      }
    }

    &-bottom {
      // border: 3px solid white;
      // border-bottom: 3px solid white;
      border-radius: 0 0 20px 20px;
      background-color: $offwhite;
      padding: var(--global-padding);

      @include respond-to('medium') {
        border-width: 0.3vw;
      }

      @include respond-to('large') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      label {
        position: relative;
      }
    }

    &--top {
      border-radius: 20px 20px 0 0;
    }

    &--name label {
      position: relative;
      padding-top: var(--global-padding);

      @include respond-to('medium') {
        position: absolute;
        padding-top: 0;
      }
    }

    input, textarea {
      background-color: transparent;
      width: 100%;
      min-height: 6vw;
      color: $orange;
      padding: var(--global-padding);
      font-size: inherit;
      font-family: inherit;
      outline: none;
      border: 0px solid transparent;

      &::placeholder {
        color: $orange;
      }
    }

    input {
      padding-left: var(--global-padding);

      @include respond-to('small') {
        padding-left: 160px;
      }

      @include respond-to('large') {
        padding-left: 15vw;
      }
    }

    textarea {
      // height: 45vw;
      min-height: 100px;
      max-height: calc(var(--100vh) / 2);
      // margin-bottom: calc(var(--global-padding) * 4);
      margin-bottom: calc(var(--global-padding) * 1);
      resize: none;
    }
  }

  label {
    font-family: "GTF Kubik";
    position: absolute;
    left: var(--global-padding);
    top: 50%;
    transform: translate(0, -50%);
    color: $orange;
    display: none;

    a {
      color: $orange !important;
    }

    @include respond-to('small') {
      display: block;
    }
  }

  .button {
    //position: absolute;
    //bottom: calc(var(--global-padding)*2);
    // left: 50%;
    // transform: translate(-50%);
    width: 100%;

    @include respond-to('large') {
      width: auto;
      flex-shrink: 0;
    }
  }

  .honeypot {
    position: absolute;
    left: -9999px;
  }

  #name {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  /*.teammembers {
    display: flex;
    flex-wrap: wrap;
    padding: var(--global-padding) 0;
    padding-top: 0;
    padding-left: var(--global-padding);

    @include respond-to('medium') {
      padding: calc(var(--global-padding) * 0.75) 0;
      padding-left: 120px;
    }

    @include respond-to('large') {
      padding-left: 10vw;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 0.5vw;
      font-size: var(--copy-small-size);
      border: 3px solid white;
      border-radius: 200px;
      margin: calc(var(--global-padding) *0.25) 0;
      margin-right: calc(var(--global-padding) *0.25);

      @include respond-to('medium') {
        font-size: var(--copy-size);
      }

      &.is-selected {
        color: $black;
        background-color: $white;
      }

      p {
        margin-left: calc(var(--global-padding) / 2);
        margin-right: calc(var(--global-padding) / 2);

        &::selection {
          background: transparent;
          color: inherit;
        }
      }

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #efefef;
        object-fit: cover;

        @include respond-to('small') {
          width: 32px;
          height: 32px;
        }

        @include respond-to('medium') {
          width: 48px;
          height: 48px;
        }
      }
    }
  }*/
}
