

/* The container */
.contactform label.checkbox-container {
  display: flex;
  font-family: Labil Grotesk, Helvetica, sans-serif;
  line-height: 1;
  align-items: center;
  position: relative;
  padding-left: 48px;
  height: 32px;
  margin-bottom: var(--global-padding);
  transform: none;
  //font-size: 22px;
  font-size: var(--copy-small-size);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  left: 0;


  a {
    text-decoration: underline;
  }

  @include respond-to('large') {
    padding-right: var(--global-padding);
    max-width: 800px;
    margin-bottom: 0;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  //background-color: #fff;
  border-radius: 6px;
  border: 3px solid $orange;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: $white;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $orange;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
