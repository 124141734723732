/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */


//make sure to align these with the ones in app.js
$black: #000;
$blacktransparent: #00000000;
$offblack: #1A1A1A;
$offblacktransparent: #1A1A1A00;
$white: #fff;
$whitetransparent: #ffffff00;
$offwhite: #F7F6F6;
$offwhitetransparent: #F7F6F600;
$orange: #FF5C00;
$offorange: #ff5100;
$orangetransparent: #FF5C0000;


/* Components */

$breakpoints: (
  small: 480px,
  medium: 720px,
  medium-x: 800px,
  large: 960px,
  wide: 1200px
);

