.webgl-can {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  width: 100vw;
  transform-origin: 0% 0%;
  // transform: scale(8);
  z-index: 100;
  pointer-events: none;
  // border: 2px solid yellow;
}

// .intro--can {
//   padding-bottom: 25lvh;
// }

.can-placeholder {
  // border: 1px solid green;
  display: block;
  position: relative;
  // border: 1px solid red;
  width: 40vw;
  // max-width: 300px;
  flex-shrink: 0;

  @include respond-to('medium') {
    width: 30lvh;
  }

  @include respond-to('large') {
    width: 40lvh;
  }

  &:after {
    content: " ";
    display: block;
    position: relative;
    padding-bottom: calc(9.82% / 5.82% * 100%); //can size in blender
  }
}

// .wegl-can-intro-placeholder {
//   border: 2px solid green;
// }

// .wegl-can-wrap-placeholder {
//   border: 2px solid blue;
// }

.can-pour {
  // border: 4px solid blue;
  background: linear-gradient(0deg, rgba(255,255,255,1) 8.9%, rgba(255,255,255,0) 9%);
  position: relative;

  &:after {
    content: " ";
    display: block;
    padding-bottom: 100%;
  }

  .can-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.can-pour-text {
  width: 100%;
  padding: var(--global-padding);
  // position: relative;
  font-size: var(--copy-size);
  margin-bottom: 2rem;

  h2 {
    font-size: 10vw;
    // font-size: var(--h1-size);
    margin-bottom: var(--global-padding);

    @include respond-to('wide') {
      text-align: right;
    }
  }

  @include respond-to('wide') {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      width: 50%;
    }
  }
}


.can-white-section {
  background-color: $white;

  --local-background: #{$white};
  --local-background-transparent: #{$whitetransparent};
  --local-offcolor: #{$offwhite};
  --local-foreground: #{$black};

  &__intro {
    color: var(--local-foreground);
    padding: var(--global-padding);
    z-index: 10;

    h3 {
      margin-bottom: var(--global-padding);
    }

    p {
      font-size: var(--copy-size);
      margin-top: var(--global-padding);
    }

    @include respond-to('medium') {
      width: calc(80% - var(--global-padding));
    }
  }
}


.can-outro {
  position: relative;
  padding: var(--global-padding);
  text-align: center;
  color: var(--local-foreground);

  &__headline {
    font-size: 10vw;
  }

  &__topline {
    font-size: var(--copy-size);
  }

  img {
    width: 50%;
    max-width: 800px;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;

    @include respond-to('medium') {
      margin-top: -5%;
      width: 30%;
    }
  }
}
