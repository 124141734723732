.credits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: var(--copy-size);
  align-items: center;
  gap: calc(var(--global-padding) / 2);
  margin: 0 var(--global-padding);
  margin-bottom: calc(var(--global-padding) * 2);

  &-name {
    font-weight: bold;
    font-family: 'GTF Kubik';
  }

  a, a:visited, a:hover, a:active {
    color: inherit;
  }

  .pivo-presents {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(var(--global-padding) * 2);

    span {
      font-size: var(--copy-small-size);
    }

    svg {
      width: 100px;
      height: auto;
      margin-right: 1rem;
    }
  }

  &-position, &-name {
    transition: color 200ms;
  }

  &-externalartist {
    text-align: center;
  }

  &-pivoartist {
    position: relative;
    margin-right: 1.5rem;
    text-align: center;

    &:hover {
      .credits-position, .credits-name {
        color: $orange;
      }
      &:after {
        transform: translate3d(50%, -20%, 0) rotate(24deg) scale(1.2);
      }
    }

    &:after {
      content: "Pivo";
      display: inline-block;
      position: absolute;
      top: 0;
      font-family: 'GTF Kubik';
      font-size: 12px;
      right: 0;
      padding: 2px 6px;
      border-radius: 4px;
      color: $white;
      background-color: $orange;
      transition: transform 400ms;
      transform: translate3d(50%, -20%, 0) rotate(24deg);
    }
  }
}
