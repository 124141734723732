
.contact-success {
  background-color: $orange;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  overflow: hidden;

  .button {
    margin-top: 2rem;
    cursor: pointer;
  }
}
