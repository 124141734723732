.theater {
  // background-color: black;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--global-padding);
  max-height: 90lvh;
  margin-left: auto;
  margin-right: auto;

  &__poster {
    // max-height: 80lvh;
    // min-height: 260px;
    // max-width: 1280px;
    width: 100%;
    height: auto;

    // @include respond-to('medium') {
    //   width: auto;
    // }
  }

  .vimeo {
    // position: relative;
    // width: 100%;

    // .preserve-ratio {
    //   width: 100%;
    //   position: relative;
    //   pointer-events: none;
    // }
  }

  .vimeo__iframe-wrapper {
    position: absolute;
    inset: 0;

    iframe {
      width: 100%;
      height: 100%;
      background-color: $black;
    }
  }
}
