.project-header {
  display: flex;
  justify-content: space-between;
  padding: var(--global-padding);
  align-items: center;

  h1 {
    font-size: var(--h2-size);
    text-align: center;
    margin: 0;
    line-height: 1;
  }
}
