.portraitcombo {
  display: grid;
  position: relative;
  margin: var(--global-padding);
  margin-bottom: calc(var(--global-padding) * 2);
  gap: calc(var(--global-padding) / 2);
  grid-template-columns: repeat(2, 1fr);
  z-index: 10;

  @include respond-to('medium') {
    grid-template-columns: repeat(3, 1fr);
    // margin-bottom: var(--global-padding);
  }

  &-item {
    display: block;
    position: relative;
    align-self: end;

    &:nth-child(2) {
      transform: translate3d(0, 50%, 0);
    }

    @include respond-to('medium') {
      margin-bottom: var(--global-padding);
      flex-direction: row;
      // align-items: stretch;
      align-self: start;
      &:nth-child(2) {
        padding-top: calc(var(--global-padding) * 2);
        transform: none;
      }
      &:nth-child(3) {
        padding-top: calc(var(--global-padding) * 4);
      }
    }
  }

  &-imagewrapper {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: calc(var(--global-padding) * 0.5);

    &:after {
      content: " ";
      position: relative;
      display: block;
      padding-bottom: calc(16 / 9 * 100%);
      pointer-events: none;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      object-fit: cover;
    }

    video {
      position: absolute;
      inset: 0;
      width: 100%;
      height: auto;
      min-height: 100%;
    }
  }


  &-caption {
    margin-bottom: var(--global-padding);
    position: relative;
    width: 100%;
    text-align: left;

    strong {
      font-family: 'GTF Kubik';
    }
  }
}
