.contact-info {
  background-color: $offblack;
  display: flex;
  padding: calc(var(--global-padding) * 4) var(--global-padding);
  margin-top: calc(var(--global-padding) * 4);
  gap: var(--global-padding);
  flex-direction: column;

  @include respond-to('medium-x') {
    flex-direction: row;
  }

  &__founders {
    width: 100%;
    border: 1px solid $black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: calc(var(--global-padding) * 2);
    gap: var(--global-padding);
  }

  &__profile {
    display: flex;
    border-bottom: 1px solid $black;
    align-items: center;
    padding-bottom: var(--global-padding);
    flex-direction: column;

    @include respond-to('medium-x') {
      flex-direction: row;
    }

    &:last-of-type {
      border-color: transparent;
      padding-bottom: 0;
    }
  }

  &__profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: var(--global-padding);
    flex-shrink: 0;
    object-fit: cover;

    @include respond-to('large') {
      width: 120px;
      height: 120px;
    }
  }

  &__profile-text {
    display: flex;
    flex-direction: column;
    font-family: "GTF Kubik";
    font-size: var(--copy-small-size);
    text-align: center;
    margin-top: var(--global-padding);
    align-items: center;

    @include respond-to('medium-x') {
      align-items: flex-start;
      margin-top: 0;
      text-align: left;
    }

    span {
      display: none;
      @include respond-to('small') {
        display: block;
      }
    }
    p {
      display: flex;
    }

    a, a:visited {
      font-family: Labil Grotesk, Helvetica, sans-serif;
      margin-left: 0.5em;
      color: white;
      transition: color 200ms;

      @include respond-to('small') {
        margin-left: 0.5em;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  &__socials {
    width: 100%;
    border-radius: 10px;
    padding: calc(var(--global-padding) * 2);
    min-height: 260px;
    text-align: center;
    background-color: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--global-padding)/2);

    a, a:visited {
      color: white;
      transition: color 200ms;
      margin: 0 calc(var(--global-padding)/2);

      &:hover {
        color: $orange;
      }
    }
  }

  &__icon-wrapper {
    display: flex;
    // flex-wrap: wrap;
  }
}
