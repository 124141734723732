/**
 * Fonts
*/

@font-face {
  font-family: 'Labil Grotesk';
  src: url("./../../assets/fonts/labilgrotesk-regular.woff2") format("woff2"),
  url("./../../assets/fonts/labilgrotesk-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GTF Kubik';
  src: url("./../../assets/fonts/kubik-black.woff2") format("woff2"),
  url("./../../assets/fonts/kubik-black.woff") format("woff"),
  url("./../../assets/fonts/kubik-black.eot") format("eot");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
