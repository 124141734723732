.vimeo-consent {
  display: block;
  position: absolute;
  width: calc(100% - (var(--global-padding) * 2));
  background-color: $orange;
  top: 50%;
  left: 50%;
  border-radius: 20px;
  padding: var(--global-padding);
  transform: translate(-50%,-50%);

  @include respond-to('medium') {
    overflow: hidden;
    max-width: 100%;
    // max-height: 100%;
    width: auto;
    min-width: 680px;
  }

  @include respond-to('large') {
    min-width: 840px;
  }

  .button {
    cursor: pointer;
  }

  &__headline {
    display: none;
    align-items: center;

    @include respond-to('small') {
      display: flex;
    }

    h2 {
      margin-left: calc(var(--global-padding)*0.5);
    }

    svg {
      width: calc(var(--global-padding)*1.5);
      height: calc(var(--global-padding)*1.5);

      @include respond-to('medium') {
        width: calc(var(--global-padding)*3);
        height: calc(var(--global-padding)*3);
      }
    }
  }

  p {
    font-size: var(--copy-small-size);
    margin-bottom: var(--global-padding);

    @include respond-to('small') {
      margin: var(--global-padding) 0;
    }

    @include respond-to('medium') {
      width: calc(100% - 180px);
    }

    a, a:visited {
      color: $white;
      text-decoration: underline;
    }
  }

  &__pigeon {
    display: none;
    pointer-events: none;

    @include respond-to('medium') {
      display: block;
      position: absolute;
      bottom: 0;
      right: var(--global-padding);
      width: 200px;
      height: 200px;
      z-index: 1;
    }
  }
}
