/* By Athlon Front-End Team */

/* Core variables */
@import 'base/vars';

/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Mixins, helpers and functions */
@import 'base/mixins';

/* Vendor */

/* Components */
@import '../components/logo-rapport/logo-rapport';
@import '../components/credits/credits';
@import '../components/theater/theater';
@import '../components/portraitcombo/portraitcombo';
@import '../components/intro/intro';
@import '../components/stage/stage';
@import '../components/imagestack/imagestack';
@import '../components/checkbox/checkbox';
@import '../components/header/header';
@import '../components/timed-messages/timed-messages';
@import '../components/contact/contact';
@import '../components/contact-info/contact-info';
@import '../components/footer/footer';
@import '../components/button/button';
@import '../components/project-list/project-list';
@import '../components/project-filter/project-filter';
@import '../components/project-header/project-header';
@import '../components/legal/legal';
// @import '../components/webgl-dither/webgl-dither';
@import '../components/webgl-can/webgl-can';
@import '../components/vimeo-consent/vimeo-consent';
@import '../components/pigeon-outro/pigeon-outro';
@import '../components/contact-success/contact-success';

@import '../components/webgl/webgl';

/* Browser specific styles */
@import 'dirty/hacks';




//CSS Variables
:root {
  --global-padding: 1rem;
  --copy-small-size: 1rem;
  --copy-size: 1.2rem;
  --copy-large-size: 1.3rem;
  // --h1-size: 1.5rem;
  --h1-size: 10vw;
  --h2-size: 1.2rem;
  --h3-size: 1rem;
  --button-text-size: 1.2rem;
  --button-large-text-size: 2rem;

  --local-background: #{$black};
  --local-background-transparent: #{$blacktransparent};
  --local-offcolor: #{$offblack};
  --local-foreground: #{$white};


  @include respond-to(small) {
    --button-text-size: 1.4rem;
  }

  @include respond-to(medium) {
    --copy-size: 1.5rem;
    --copy-small-size: 1.2rem;
    --copy-large-size: 1.6rem;
    // --h1-size: 4.5vw;
    --h2-size: 3.5vw;
    --h3-size: 1.8vw;
    --button-text-size: 2.2rem;
    --button-large-text-size: 3.5rem;
  }

  @include respond-to(large) {
    --global-padding: 2.5vw;
    --copy-size: 1.6vw;
    --copy-large-size: 2vw;
    --button-text-size: 3rem;
  }

  @include respond-to(wide) {
    --copy-small-size: 1.2vw;
    // --h3-size: 2vw;
  }
}




body {
  background: $offblack;
  color: $white;
  font-family: 'Labil Grotesk', 'Helvetica', sans-serif;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0;

  @include respond-to(medium) {
    font-size: 1rem;
  }

  &.is-loading {
    .container {
      opacity: 0;
    }
  }
}

* {
  box-sizing: border-box;
  &::selection {
    color: $white;
    background: rgba(255, 92, 0, 1);
  }
}

.resize-helper {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  height: 100lvh;
  // border: 10px solid red;
}


//template colors
//make sure to align this with the colors in app.js

.culture, .contact { //on orange
  --local-background: #{$orange};
  --local-background-transparent: #{$orangetransparent};
  --local-offcolor: #{$offwhite};
  --local-foreground: #{$white};

  *::selection {
    color: $orange;
    background: $offwhite;
  }
}

.studios, .legal {
  --local-background: #{$offwhite};
  --local-background-transparent: #{$offwhitetransparent};
  --local-offcolor: #{$white};
  --local-foreground: #{$black};
}

.project {
  --local-background: #{$offblack};
  --local-background-transparent: #{$offblacktransparent};
  --local-offcolor: #{$black};
  --local-foreground: #{$white};
}




// a, a:visited, a:hover, a:active {
//   color: inherit;
// }

h1, h2, h3 {
  font-family: 'GTF Kubik';
}

h1 {
  font-size: var(--h1-size);
  line-height: 1;
  font-weight: bold;
  margin-top: 1rem;
}

h2 {
  font-size: var(--h2-size);
  line-height: 1;
  font-weight: bold;
}

h3 {
  font-size: var(--h3-size);
  line-height: 1;
  font-weight: bold;
}

img, figure {
  width: 100%;
  display: inherit;
  height: auto;
}

//.video,
.img {
  position: relative;
  display: block;
  --w: 1;
  --h: 1;
  padding-bottom: calc(100% / var(--w) * var(--h));
  background: var(--color-black);
}

.video {
  video {
    width: 100%;
  }
}

.container {
  // background-color: var(--local-background);
  color: var(--local-foreground);
  /*position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;*/
  // position: relative;
  z-index: 80;
  //border: 1px solid red;
  width: 100%;

  &.onload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    //bottom: 0;
  }
}
// main {
  //padding: 1rem;
  // min-height: 100vh;
// }

.blocks p {
  display: block;
  position: relative;
  margin: 0 var(--global-padding);
  z-index: 10;
}

.grid {
  --columns: 12;
  //--gutter: 3rem;
  --gutter: var(--global-padding);
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
  max-width: 1280px;
  margin: 0 auto;

  p {
    margin: 0;
    padding: var(--global-padding);
    font-size: var(--copy-size);
  }
}

.grid > .column {
  margin-bottom: var(--gutter);
}

.autogrid {
  --gutter: 3rem;
  --min: 10rem;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));
  grid-auto-flow: dense;
}

@media screen and (min-width: 60rem) {
  /*body {
    --padding: 3rem;
  }*/

  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > .column {
    grid-column: span var(--columns);
  }

}

.team-image {
  width: calc(100% - (var(--global-padding) * 2));
  max-height: calc(var(--100vh) - (var(--global-padding) * 2));
  margin: var(--global-padding);
  margin-top: 0;
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
}


.copied-message {
  padding: 0.5rem 1rem;
  margin: 0;
  pointer-events: none;
  width: auto;
  height: 40px;
  position: fixed;
  display: block;
  top: 1rem;
  left: 50%;
  background-color: #085AFA;
  border-radius: 100px;
  transform: translate3d(-50%, -5rem, 0);
  opacity: 0;
  z-index: 50;

  &.show {
    animation: showMessage 2400ms;
    display: block;
  }
}

@keyframes showMessage {
  0% {
    transform: translate3d(-50%, -5rem, 0);
    opacity: 1;
  }
  30% {
    transform: translate3d(-50%, 0, 0);
  }
  70% {
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, -5rem, 0);
    opacity: 1;
  }
}



// .webgl {
//     position: fixed;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     outline: none;
//     z-index: -20;
// }
