
.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    // z-index: 50;
    pointer-events: none;
    z-index: 100;
}

.webglDither {
  pointer-events: none;
  image-rendering: pixelated;
  transform-origin: 0 0;
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100% !important;
  height: 100%;
  height: 100lvh !important;

  &.transparentBlack {
    mix-blend-mode: screen;
    z-index: 50;
  }
}

.loading-bar {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0.3);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended {
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

.point {
    position: fixed;
    z-index: 0;
    top: 50%;
    left: 50%;
    pointer-events: none;
}

.point .label {
    position: absolute;
    top: 0;
    left: 42px;
    margin-left: 12px;
    background-color: white;
    border-radius: 40px;
    white-space: nowrap;
    padding: 12px 24px;
    opacity: 0;
    transition: opacity 300ms, color 200ms, background-color 200ms;
    transition-delay: 0ms;
    user-select: none;
}

.point:hover .label {
    color: white;
    background-color: black;
    cursor: pointer;
}

.point.selected .label {
    color: white;
    background-color: black;
}

.point svg {
    transform: scale(0, 0);
    width: 42px;
    height: 42px;
    transition: transform 300ms;
    transition-delay: 100ms;
}

.point.visible {
    pointer-events: all;
}

.point.visible svg {
    transform: scale(1, 1);
    transition-delay: 0ms;
}

.point.visible .label {
    transition-delay: 100ms;
    opacity: 1;
}
