.logo-rapport {
  color: $white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: calc(var(--global-padding) / 2);
  margin: var(--global-padding);

  @include respond-to('small') {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  color: var(--local-offcolor);

  &--orange {
    color: $orange;
  }

  &--single {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 0;
    // margin-top: var(--global-padding);

    // svg:nth-child(3) {
    //   display: none;
    //   @include respond-to('small') {
    //     display: block;
    //   }
    // }
  }

  // &-noverticalspace {
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }

  // &--offwhite {
  //   color: $offwhite;
  // }
  // &--black {
  //   color: $black;
  // }
  // &--offblack {
  //   color: $offblack;
  // }

  svg {
    width: 100%;
    height: auto;
  }
}
