.project-list {
  // overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: repeat(3, 1fr);
  grid-gap: calc(var(--global-padding) / 2);
  margin: var(--global-padding);
  margin-top: calc(var(--global-padding) / 2);
  overflow: visible;
  // min-height: 50lvh;

  @include respond-to('medium') {
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    // perspective: 1500px;
  }

  a, a:visited, a:hover, a:active {
    color: inherit;
  }

  image {
    // background-color: $offblack;
  }

  .project__item-wrapper {
    position: relative;
    perspective: 1500px;
  }

  .project__item {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    // background-color: $black;
    opacity: 0;
    // overflow: hidden;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: transform 100ms;

    @include respond-to('medium') {
      &:before {
        content: " ";
        display: block;
        position: relative;
        padding-bottom: 56.25%;
        pointer-events: none;
      }
    }

    mark {
      background-color: transparent;
      color: inherit;
      display: inline-block;
      overflow: hidden;
    }

    .imagewrapper {
      position: relative;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      pointer-events: none;
      background: linear-gradient(45deg, rgba(26,26,26,1) 0%, rgb(39, 38, 38) 30%, rgba(26,26,26,1) 60%);

      &:before {
        content: " ";
        display: block;
        position: relative;
        padding-bottom: 56.25%;
        pointer-events: none;
      }

      @include respond-to('medium') {
        transform-style: preserve-3d;
        transition: transform 300ms;
        transition-delay: 200ms;
        // transition: opacity 400ms;
        position: absolute;
        inset: 0;

        &:before {
          display: none;
        }
      }

      // @include respond-to('medium') {
      //   box-shadow: 0px 0px 26px 5px #000000;
      // }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      inset: 0;

      @include respond-to('medium') {
        position: relative;
      }
      // transform: scale(1.2, 1.2);
    }

    &:hover {
      @include respond-to('medium') {
        // transform: scale3d(1.07, 1.07, 1.07) rotate3d(1,1,0, 25deg);

        .project__title span {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }

        img {
          // opacity: 0.8;
          // transform: scale(1, 1);
        }
      }
    }

    .project__title {
      width: 100%;
      -webkit-font-smoothing: subpixel-antialiased;
      margin-top: calc(var(--global-padding) / 2);

      @include respond-to('medium') {
        position: absolute;
        margin-top: 0;
        left: 0;
        top: 50%;
        text-align: center;
        transform: translate3d(0, -50%, 20px);
        pointer-events: none;
        transform-style: preserve-3d;
        padding: calc(var(--global-padding)*0.5);

        span {
          display: inline-block;
          transform: translate3d(0, 100%, 0);
          // opacity: 0;
          transition: transform 200ms, opacity 200ms;
          @for $i from 1 to 20 {
            &:nth-child(#{$i}) { transition-delay: $i * 0.05s + 0.2s; }
          }
        }
      }
    }
  }
}
