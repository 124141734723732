.timed-messages {
  color: var(--local-foreground);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--global-padding) / 2);
  padding-bottom: calc(var(--global-padding) * 4);
  // margin-bottom: calc(var(--global-padding) * 3);
  margin-bottom: var(--global-padding);
  background-color: $offwhite;
  width: 100vw;
  overflow: hidden;

  &__list {
    font-size: var(--h2-size);
  }

  .clock {
    font-size: 10vw;
    display: block;
  }

  p {
    font-size: var(--copy-size);
    width: 80%;
    max-width: 550px;
    margin-top: calc(var(--global-padding) * 2);
  }

  &__marquee {
    background-color: var(--local-foreground);
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: calc(var(--global-padding) * 4);

    .wrapper {
      display: flex;
      align-items: center;
      color: var(--local-background);
      width: fit-content;
      animation: ticker 24s linear infinite forwards;
    }

    span {
      white-space: nowrap;
    }

    img {
      width: 24px;
      margin-left: 30px;
      margin-right: 10px;
      filter: invert(1);
    }
  }
}


@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
