.header {
  /*position: fixed;
  left: 0;
  right: 0;
  top: 0;*/
  position: relative;
  padding-top: calc(var(--global-padding)*0.5);
  padding-bottom: calc(var(--global-padding)*0.5);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  //background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;

  a, a:visited, a:hover, a:active {
    color: inherit;
  }

  &-menu {
    display: flex;
    color: var(--local-foreground);

    a {
      display: flex;
      align-items: center;
      padding-right: var(--global-padding);
      padding-top: calc(var(--global-padding)*0.5);
      padding-bottom: calc(var(--global-padding)*0.5);
      font-size: 16px;

      @include respond-to('small') {
        font-size: var(--copy-size);
        padding-right: calc(var(--global-padding)*1.5);
      }

      @include respond-to('medium') {
        padding-right: var(--global-padding);
      }
      // transition: color 200ms;

      // &:hover {
      //   color: var(--local-background);
      // }
    }

    .active {
      pointer-events: none;
      &:before {
        content: " ";
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background-color: var(--local-foreground);
        margin-right: 10px;
      }
    }

    // &--black {
    //   color: black;
    //   .active:before {
    //     background-color: black;
    //   }
    // }
  }


}
