.pigeon-outro {
  padding: var(--global-padding);

  h2 {
    font-size: 9vw;
    // margin-top: -66.66%;
    transform: translate3d(0, -66.666%, 0);
    margin-bottom: -14vw;
  }

  &__pigeons {
    width: 45vw;
    margin-left: auto;
    margin-right: 0;

    @include respond-to('medium') {
      width: 50vw;
    }
  }
}
