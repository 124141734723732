.back-button {
  opacity: 0; // is animated in events.js
}

.button {
  display: inline-block;
  position: relative;
  color: $black;
  background-color: $white;
  text-decoration: none;
  overflow: hidden;
  border-radius: 200px;
  text-transform: uppercase;
  font-family: 'GTF Kubik';
  font-weight: normal;
  padding: 0.75rem 2rem;
  font-size: var(--button-text-size);
  text-align: center;
  flex-shrink: 0;
  line-height: 1;
  transition: color 300ms, background-color 300ms;

  @include respond-to('medium') {
    padding: 1.5rem 2.8rem;
    // padding: 1vw 2vw;
  }

  &:hover {
    background-color: $white;
    color: $orange;
  }

  &--close {
    padding: 0.5rem;
    color: $black;
    font-size: 0;

    svg {
      display: inline-block;
      width: 32px;
      height: 32px;
    }
  }

  &--cta {
    color: $white;
    background-color: $orange;

    &:hover {
      background-color: $black;
      color: $orange;
    }
  }

  // &__initial {
  //   transition: transform 300ms;
  //   position: relative;
  //   display: block;
  // }

  // &__active {
  //   position: absolute;
  //   width: 100%;
  //   text-align: center;
  //   left: 0;
  //   top: 50%;
  //   transition: transform 300ms;
  //   transform: translate3d(0, 80%, 0);
  // }

  // span {
  //   &::selection {
  //     background: transparent;
  //     color: inherit;
  //   }
  // }

  // &.switch {
  //   .button__initial {
  //     transform: translate3d(0, -150%, 0);
  //   }

  //   .button__active {
  //     transform: translate3d(0, -50%, 0);
  //   }
  // }

  // &.active {
  //   background-color: $white;
  //   color: $black;
  // }
}
