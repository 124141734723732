.imagestack {
  display: block;
  position: relative;
  margin: var(--global-padding);
  padding-bottom: 50px; //prevent last image from stopping to early

  &-item {
    position: sticky;
    margin-bottom: var(--global-padding);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: calc(var(--global-padding)*4);

    @include respond-to('medium') {
      flex-direction: row;
      align-items: stretch;
    }
  }

  &-imagewrapper {
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: -3px 5px 8px 0px rgba(0,0,0,0.16);

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .preserve-space {
      position: relative;
      width: 100%;
      // pointer-events: none;
    }
  }
}
