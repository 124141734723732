.project-filter {
  position: relative;
  display: grid;
  grid-gap: calc(var(--global-padding) / 2);
  margin: var(--global-padding);
  margin-bottom: 0;
  grid-template-columns: repeat(1, 1fr);

  @include respond-to('medium') {
    grid-template-columns: repeat(2, 1fr);
  }

  &-result {
    margin-top:calc(var(--global-padding) * 1);
  }

  &.singlename-active {
    .project-filter-singlename {
      display: flex;
    }
    .project-filter-services, .project-filter-names {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &-singlename {
    position: absolute;
    display: none;
    align-items: center;
    gap: var(--global-padding);
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -60%, 0) rotate(-8deg);
    padding: calc(var(--global-padding)*1);
    border-radius: 10px;
    background-color: $orange;
    color: $white;
    z-index: 10;

    @include respond-to('large') {
      padding: var(--global-padding);
    }

    .singlename {
      font-size: 6vw;
      white-space: nowrap;

      @include respond-to('medium') {
        font-size: 3vw;
      }
    }

    .topline {
      // height: 0;
      // transform: translateY(-20px);
      font-size: 4vw;
      // position: absolute;
      // top: 0;

      @include respond-to('small') {
        font-size: 2vw;
      }

      @include respond-to('medium') {
        font-size: 1.2vw;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
    }

    svg {
      cursor: pointer;
      width: calc(var(--global-padding)*2);
      height: calc(var(--global-padding)*2);
      transition: color 200ms;

      @include respond-to('large') {
        width: var(--global-padding);
        height: var(--global-padding);
      }

      &:hover {
        color: $offblack;
      }
    }
  }

  &-services, &-names {

    .info {
      display: flex;

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }

      .label {
        text-transform: uppercase;
        margin-right: 1rem;
      }

      .reset {
        background-color: $white;
        color: $black;
        padding: 0 0.5rem;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .options {
      width: 100%;
      flex-shrink: 0;
      margin-top: calc(var(--global-padding) / 2);
      min-height: calc(var(--global-padding) * 2);

      button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        font-family: 'GTF Kubik';
        margin-right: var(--global-padding);
        // color: $offblack;
        color: #3a3a3a; //requested to make lighter
        font-size: var(--copy-large-size);
        transition: color 200ms;
        line-height: 1;

        &:hover {
          color: #484848;
        }

        &.active {
          color: $white;
        }
      }
    }
  }

  &-artistprofile {

    &-wrapper {
      grid-gap: calc(var(--global-padding) / 2);
      margin: var(--global-padding);
      // margin-top: calc(var(--global-padding) / 2);
      margin-top: 0;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      display: grid;
      position: relative;
      background-color: $offblack;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;

      @include respond-to('large') {
        font-size: var(--copy-size);
      }
    }

    &-image {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      grid-column: 1 / 5;

      @include respond-to('small') {
        height: 100%;
        grid-column: 1 / 3;
      }

      @include respond-to('medium') {
        grid-column: 1 / 2;
      }
    }

    &-description {
      padding: calc(var(--global-padding) / 2);
      grid-column: 1 / 5;
      padding-top: 0;

      @include respond-to('small') {
        padding-left: 0;
        padding-top: calc(var(--global-padding) / 2);
        grid-column: 3 / 5;
      }

      @include respond-to('medium') {
        grid-column: 2 / 5;
      }

      h2 {
        margin-bottom: calc(var(--global-padding) / 2);
      }

      a {
        color: $white;
        text-decoration: underline;

        &:visited {
          color: $white;
        }
      }
    }
  }
}
