.stage {
  position: relative;
  display: block;
  overflow: visible;
  // border: 1px solid red;

  @include respond-to('medium') {
    perspective: 1500px;
  }

  &:before {
    content: " ";
    display: block;
    position: relative;
    padding-bottom: 56.25%;
  }

  &__wrapper {
    // border: 1px solid yellow;
    position: absolute;
    inset: 0;
    transition: transform 100ms;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  &__card {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 10px;
    position: absolute;
    background-color: $offblack;
    display: none;
    top: 50%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: transform 300ms;

    &:before {
      content: " ";
      display: block;
      position: relative;
      padding-bottom: 56.25%;
    }

    &--reel {
      display: block;
      overflow: hidden;
      width: calc(var(--global-padding) * -2 + 100%);
      left: var(--global-padding);
      top: var(--global-padding);
      cursor: pointer;
      // transform: scale3d(1.07, 1.07, 1.07) rotate3d(1,1,0, 25deg);
      // transition: width 500ms, left 500ms;

      @include respond-to('medium') {
        width: 56%;
        left: 22%;
        top: 22%;
        transform: translate3d(0,0,200px);
        transition: transform 500ms;
      }

      // &:hover {
      //   transform: translate3d(0,0,200px);
      // }

      video {
        position: absolute;
        inset: 0;
      }

      .stage__volume {
        position: absolute;
        left: calc(var(--global-padding)/2);
        bottom: calc(var(--global-padding)/2);
        background-color: rgba(255,255,255,0.2);
        color: $white;
        padding: calc(var(--global-padding)/4);
        border-radius: 50%;
        transition: opacity 200ms 500ms, color 200ms 0ms;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        transform: translate3d(0,0,50px);

        &:hover {
          background-color: rgba(255,255,255,0.4);
        }

        .muted {
          display: none;
          width: 24px;
          height: auto;
        }
        .volume-on {
          display: block;
          width: 24px;
          height: auto;
        }
      }

      &.active {
        transform: translate3d(0,0,400px);
        // border: 1px solid red;

        .stage__volume .muted {
          display: block;
        }
        .stage__volume .volume-on {
          display: none;
        }

        .stage__volume {
          opacity: 0;
        }

        &:hover {
          .stage__volume {
            opacity: 1;
          }
        }
      }
    }
    &--culture {
      width: 30%;
      left: 10%;
      top: 15%;

      &:hover {
        transform: translate3d(15%,15%,220px);
      }

      .stage__can {
        position: absolute;
        top: -20%;
        left: 0%;
        width: 60%;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        transform: translate3d(0,0,150px);
      }

      .stage__info {
        // text-align: right;
        text-align: center;
      }

      .stage__culture {
        overflow: hidden;
        border-radius: 10px;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--studios {
      width: 30%;
      left: 60%;
      top: 100% - 15% - 30%;

      &:hover {
        transform: translate3d(-15%,-15%,220px);
      }

      .stage__studios {
        overflow: hidden;
        border-radius: 10px;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @include respond-to('medium') {
      display: block;
    }
  }

  &__info {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    padding: 5%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: translate3d(0,0,50px);
    color: $white !important;
  }

  // transform: scale3d(1.07, 1.07, 1.07) rotate3d(1,1,0, 25deg);
}


.initial-state {
  .stage__card--studios {
    transform: translate3d(-50%,-50%,0);
  }
  .stage__card--culture {
    transform: translate3d(50%,50%,0);
  }
  .stage__card--reel {
    transform: translate3d(0,0,400px);
  }
}
